import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appTempx]',
  standalone: true
})
export class TempxDirective {

  @Input()
  set appTempxx(inx: string) {

  }

  constructor() { }

}
