import { Inject, Injectable, WritableSignal, signal } from '@angular/core';
import { User } from '../models/user';
import { LocalStorageWithContextService } from '../../common/services/local.storage.with.context.service';


@Injectable({
  providedIn: 'root'
})
export class UserContextService {
  constructor(
    private localStorage: LocalStorageWithContextService
  ) {
  }

  public get(key:string, type: any, defaultValue: any = null): any {
    let storedValue: any = undefined;
    let value: any = defaultValue;

    if(type == Object) {
      storedValue = this.localStorage.getObject(key, true);
      value = storedValue ? storedValue : value;
    } else {
      storedValue = this.localStorage.get(key, true);
      if(storedValue !== null && storedValue !== undefined) {
        switch(type) {
          case Boolean:
            value = storedValue == "true" ? true : false;
            break;
          default:
            value = storedValue;
        }
      }
    }

    return value;
  }

  public set(key:string, type: any, value: any) {
    if(type == Object) {
      this.localStorage.setObject(key, value, true);
    } else {
      this.localStorage.set(key, String(value), true);
    }
  }

}
