<div class="w-full h-full flex">
  <div
    [@openCloseMenu]="!menuCollapsed ? 'open' : 'closed'"
    class="border-rx flex flex-col items-center justify-between p-2 overflow-y-scroll"
    style="border-color: #3f4948; background-color: #2e2e2e; width: 300px; scrollbar-width: thin; scrollbar-color: #0d0d0d #191c1c;"
  >
    <mat-nav-list
      [@openCloseNav]="!menuCollapsed ? 'open' : 'closed'"
    >
      <div class="w-full flex justify-center mt-2 mb-2">
        <div
          class="flex rounded-full p-2 xbg-red-900 border"
          style="
            border-color: rgba(255, 255, 255, 0.05);
            /*
            -webkit-box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
            -moz-box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
            box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
            */
            -webkit-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
            -moz-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
          "
        >
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="0.1" stroke="currentColor" class="size-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
          </svg> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.6" stroke="currentColor" class="size-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
          </svg>

        </div>
      </div>
      <div class="w-full text-xs flex justify-center mb-6 text-center text-gray-300">
        <p class="w-16">{{appName}}</p>
      </div>
      <mat-list-item>
        <a class="flex" [routerLink]="['/']" routerLinkActive="router-link-active">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Pulpit"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            dashboard
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Pulpit</div>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a class="flex" [routerLink]="['/sklepy']" routerLinkActive="router-link-active">
          <!-- <mat-icon
            class="material-icons-outlined"
            matTooltip="Sklepy"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            notifications_active
          </mat-icon> -->
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Sklepy"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            storefront
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Sklepy</div>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a class="flex" [routerLink]="['/ostrzezenia']" routerLinkActive="router-link-active">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Ostrzeżenia"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            error
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Ostrzeżenia</div>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a class="flex" [routerLink]="['/ustawienia']" routerLinkActive="router-link-active">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Ustawienia"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            tune
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Ustawienia</div>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a class="flex" [routerLink]="['/zadania', 'definicje_zadan']" routerLinkActive="router-link-active">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Definicje zadań"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            splitscreen
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Definicje zadań</div>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a class="flex" [routerLink]="['/zadania', 'zlecenia_zadan']" routerLinkActive="router-link-active">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Definicje zadań"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            widgets
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Zlecenia zadań</div>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a class="flex" [routerLink]="['/zadania', 'zadania']" routerLinkActive="router-link-active">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Definicje zadań"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            view_timeline
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Zadania</div>
        </a>
      </mat-list-item>
      <div class="my-2">
        <mat-divider></mat-divider>
      </div>
      <mat-list-item>
        <a class="flex" [routerLink]="['/uzytkownicy']" routerLinkActive="router-link-active">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Użytkownicy"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            group
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Użytkownicy</div>
        </a>
      </mat-list-item>
      <div class="my-2">
        <mat-divider></mat-divider>
      </div>


      <!-- @for (link of links; track link) {
        <mat-list-item [activated]="link.isActive" *userHasRole="[RoleKey.operator]">
           <a matListItemTitle [routerLink]="link.link" class="flex jusify-center items-center"><mat-icon class="mr-2 material-icons-outlined">notifications_active</mat-icon><span [@menuItemCollapsed]="menuCollapsed ? 'open' : 'closed'" class="xxx">{{ link.name }}</span></a>
        </mat-list-item>
      } -->
      <!-- <mat-list-item *ngIf="authService.user()">
        <button
          mat-button
          aria-label="Wyloguj się"

        >
          Wyloguj się
        </button>
      </mat-list-item> -->
      <!-- <div class="text-xs text-center">
        {{user?.firstname}} {{user?.lastname}}
      </div> -->
      <mat-list-item>
        <a class="flex" [routerLink]="['/konto/zmiana_hasla']" routerLinkActive="router-link-active">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Zmiana hasła"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            password
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Zmiana hasła</div>
        </a>
      </mat-list-item>
      <mat-list-item class="flex items-center">
        <button (click)="authService.logout().subscribe()" type="button" class="flex">
          <mat-icon
            class="material-icons-outlined"
            matTooltip="Wyloguj się"
            matTooltipPosition="right"
            [matTooltipDisabled]="!menuCollapsed"
          >
            logout
          </mat-icon>
          <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Wyloguj się</div>
        </button>
        <!--  -->
      </mat-list-item>
    </mat-nav-list>
    <div class="w-full">
      <p class="text-xs w-full text-center text-gray-300">{{environmentName}}, v: {{version}}</p>
      <div class="my-2">
        <mat-divider></mat-divider>
      </div>
      <div class="flex justify-end">
        <button (click)="toggleMenu()" mat-icon-button aria-label="Zwiń/rozwiń menu"
        [matTooltip]="menuCollapsed ? 'Zwiń menu' : 'Rozwiń menu'"
        matTooltipPosition="right"
        >
          <mat-icon
            class="fam-switch"
            [ngClass]="{'fam-rotated': menuCollapsed}"
          >
            chevron_left
          </mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="w-full h-full overflow-hidden">
    <router-outlet />
  </div>
</div>

