<div class="fam-full-screen">
  <div class="flex">
    <div>
      <header class="flex flex-col justify-between">
        <fam-identity></fam-identity>
        <h1 class="p-4 text-3xl">
          Zaloguj się
        </h1>
        <p *ngIf="error" class="px-4 text-red-400">{{error}}</p>
      </header>
      <div class="flex flex-row justify-center items-start">
        <form class="p-4 w-1/3 min-w-[400px]" #ngUserForm="ngForm" *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

          <div class="flex mb-4">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Email</mat-label>
              <input
                type="email"
                matInput
                formControlName="email"
                [errorStateMatcher]="errorStateMatcher"
                placeholder="Adres email"
              >
              <mat-error>{{getError('email')}}</mat-error>
            </mat-form-field>
          </div>

          <div class="flex mb-4">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Hasło</mat-label>
              <input
                type="password"
                matInput
                formControlName="password"
                [errorStateMatcher]="errorStateMatcher"
                placeholder="Hasło"

              >
              <mat-error>{{getError('password')}}</mat-error>
            </mat-form-field>
          </div>

          <div class="flex mb-6 justify-between items-center">
            <a mat-button [routerLink]="['/auth', 'zapomnialem_hasla']" routerLinkActive="router-link-active">Nie pamiętam hasła</a>
            <button type="submit" mat-flat-button color="primary"><mat-icon>login</mat-icon>Zaloguj się</button>
          </div>
          <mat-divider></mat-divider>
          <div class="mt-6">
            <fap-version></fap-version>
          </div>
        </form>
      </div>
    </div>
    <ng-container *ngIf="users.length > 0">
      <mat-divider [vertical]="true"></mat-divider>
      <div class="px-4">
        <h3 class="mb-2">Zaloguj jako:</h3>
        <ul>
          <li *ngFor="let user of users"><button (click)="loginAs(user)" mat-button>{{user.name}} ({{user.firstname}} {{user.lastname}}): {{user.roles.join(", ")}}</button></li>
        </ul>
      </div>
    </ng-container>
  </div>

</div>





