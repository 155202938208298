import { Component } from '@angular/core';

@Component({
  selector: 'app-temp',
  standalone: true,
  imports: [],
  templateUrl: './temp.component.html',
  styleUrl: './temp.component.scss'
})
export class TempComponent {

}
