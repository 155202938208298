
import { Directive, Input, TemplateRef, ViewContainerRef, Renderer2, effect, input } from '@angular/core';
import { AuthService } from '../../../../frio-lib/src/lib/auth-module/services/auth.service';
import { RoleKey } from '../../../../frio-lib/src/lib/auth-module/models/role-key';

@Directive({
  selector: '[userHasRole]',
  standalone: true
})

export class ShowIfHasRoleDirective {

  userHasRole = input.required<RoleKey[]>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService
  ) {
    effect(() => {
      if(this.userHasRole().length == 0) {
        this.disableComponent();
      } else if(this.userHasRole().length > 0) {
        if(this.authService.loggedUserPerformsOneOfTheRoles(this.userHasRole())) {
          this.enableComponent();
        } else {
          this.disableComponent();
        }

        // if(this.authService.user()?.roles[0] == this.userHasRole()[0]) {
        //   console.log("Autoryzacja pozytywna");
        //   this.enableComponent();
        // } else {
        //   console.log("Autoryzacja negatywna");
        //   this.disableComponent();
        // }
      } else {
        this.enableComponent();
      }
    });
  }





  // @Input()
  // set userHasRole(userPermission : string[]) {
  //   const x = const doubleCount: Signal<number> = computed(() => count() * 2);


  //   effect(() => {
  //     this.authService.user();
  //     console.log("Teraz działam!");
  //   });
  // }

  decideView(value: string[]) {
   // your business logic to call the functions
  }

  disableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement : HTMLElement = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
    viewRootElement.setAttribute('style', 'display: none');
    //this.renderer.setProperty(viewRootElement, 'disabled', true);
  }

  enableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement : HTMLElement = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
    //this.renderer.setProperty(viewRootElement, 'disabled', false);
  }

  removeComponent(): void {
    //this.viewContainerRef.clear();
  }

  showComponent(): void {
    // this.viewContainerRef.clear();
    // this.viewContainerRef.createEmbeddedView(
    //   this.templateRef
    // ).rootNodes[0];
  }

}
