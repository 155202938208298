import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../../frio-lib/src/lib/auth-module/services/auth.service';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export const authGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  const snackBar: MatSnackBar = inject(MatSnackBar);

  if(authService.user()) {

  } else {
    router.navigate(['/login']);
  }

  const roles: string[] | null = route.data['roles'];
  const isAuthorized: boolean = route.data['roles']
    ? authService.loggedUserPerformsOneOfTheRoles(route.data['roles'])
    : true;

  if(!isAuthorized) snackBar.open("Próba nieuprawnionego dostępu.", undefined, { duration: 2000 });

  return isAuthorized;
};
