import { CommonModule } from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

export interface ErrorDialogData {
    message: any;
    errors: any;
    controller: any;
}

@Component({
  selector: 'error-dialog',
  templateUrl: 'error-dialog.component.html',
  standalone: true,
  imports: [CommonModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent {
    message!: any;
    errors!: any;

    constructor(
        public dialogRef: MatDialogRef<ErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData,
    ) {}


    ngOnInit(): void {
        this.message = this.data.message;
        this.errors = this.getErrorsList();
    }

    private getErrorsList() {
        const errors: { key: string, error: string }[] = [];

        if(this.data.errors) {
            if (typeof this.data.errors === 'string' || this.data.errors instanceof String) {
                errors.push({
                    key: "",
                    error: this.message.errors as string
                });
            } else {
                for (const [key, value ] of Object.entries(this.data.errors)) {
                    if(Array.isArray(value)) {
                        if(value.length == 1 && this.message == value[0]) return;

                        errors.push({
                            key: key,
                            error: value.reduce(function(previousValue: any, currentValue: any) {
                                return previousValue + ", " + currentValue;
                            })
                        });
                    }
                }
            }
        }

        console.log(errors);

        return errors;
    }
}
