<h2 mat-dialog-title>
  <span class="">Błąd danych (Błąd komunikacji z API)</span>
</h2>
<div mat-dialog-content>
    <div class="mr-8">{{message}}</div>
    <div class="mt-4" *ngIf="errors">
        <ul class="list-disc list-inside">
            <li class="" *ngFor="let error of errors; index as i;">{{error.error}}</li>
        </ul>
    </div>
</div>
<div mat-dialog-actions>
    <div class="py-4 flex justify-end w-full">
        <button  mat-flat-button color="primary" mat-dialog-close>Zamknij</button>
    </div>
</div>
