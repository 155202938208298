import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../../../frio-lib/src/lib/auth-module/services/auth.service';
import { ErrorDialogComponent } from '@common/components/errors/error-dialog/error-dialog.component';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        public dialog: MatDialog
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const passErrorsBack: boolean =
        request.params.has("pass_errors")
          ? request.params.get("pass_errors") == "true"
          : false;

      const requestParams: HttpParams = new HttpParams();
      request.params.keys().forEach(key => {
        const value = request.params.get(key);
        if(key != "pass_errors" && value) {
          requestParams.append(key, value);
        }
      });

      request = request.clone();

      return next.handle(request).pipe(
        catchError(
            (error: HttpErrorResponse) => {

                console.log("HttpErrorInterceptor - obsługa błędu!", error);
                //this.logsService.addItem("error", JSON.parse(JSON.stringify(error)));

                if (error.status === 504) {
                    this.showError(new HttpErrorResponse(
                        {
                            error: {
                                message: "Brak odpowiedzi serwera - sprawdź czy masz połączenie z Internetem."
                            }
                        }
                    ));
                    return EMPTY;
                } else if (error.status === 0) {
                    this.showError(new HttpErrorResponse(
                        {
                            error: {
                                message: "Błąd komunikacji z serwerem."
                            }
                        }
                    ));

                    return EMPTY;
                } else if (error.status === 401) {
                    // 401 Unauthorized ()

                    this.authService.logout();
                    this.showError(new HttpErrorResponse(
                        {
                            error: {
                                message: error.error.message == "Unauthenticated." ? "Zostałeś wylogowany - zaloguj się ponownie." : "Próba nieuprawnionego dostępu - zaloguj się ponownie."
                            }
                        }
                    ));

                    return EMPTY;
                } else if (error.status === 422) {
                    // 422 Unprocessable Entity
                    if(!passErrorsBack) {
                      this.showError(error);
                    }
                    return throwError(() => error);
                    //return EMPTY;
                } else if (error.status === 404) {
                    // 404 Not Found
                    this.showError(new HttpErrorResponse(
                        {
                            error: {
                                message: "Wskazany zasób nie jest już dostępny."
                            }
                        }
                    ));
                    return EMPTY;
                } else if (error.status === 409) {
                  // 409 Conflict
                  this.showError(error);
                  return EMPTY;
                } else if (error.status === 403) {
                  // 403 (Forbidden)
                  this.showError(error);
                  return throwError(() => error);
                } else {
                    this.showError(error);
                    return EMPTY;
                }
            }
        )
      );
    }

    public dialogRef: MatDialogRef<any> | null = null;

    protected showError(error: HttpErrorResponse) {
        if(this.dialogRef) return;

        const dialogRef = this.dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
                message: error.error.message ? error.error.message : error.error.error ? error.error.error : null,
                errors: error.error.errors ? error.error.errors : null,
                controller: this
            },
            maxWidth: '95vw',
            maxHeight: '95vh'
        });

        this.dialogRef?.afterClosed().subscribe(
            {
                next: () => {
                    this.dialogRef = null;
                }
            }
        )
    }

    public dialogClosed() {
        if(this.dialogRef) {
            this.dialogRef = null;
        }
    }
}
