import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FiltersComponent } from '@common/components/lists/filters/filters/filters.component';
import { AuthService } from '../../../../../../../frio-lib/src/lib/auth-module/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FieldErrorStateMatcher } from '@common/services/forms/field-error-state-matcher.service';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../../../../../frio-lib/src/lib/auth-module/models/user';
import { AuthCredentials } from '../../../../../../../frio-lib/src/lib/auth-module/models/auth-credentials';
import { IdentityComponent } from '@common/components/layout/identity/identity.component';
import { VersionComponent } from '@common/components/layout/version/version.component';

const validationMessages: { [key:string]: string } = {
  required: "Wypełnij pole",
  email: "Wprowadź prawidłowy adres email"
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSortModule,
    FormsModule,
    FiltersComponent,
    MatTooltipModule,
    MatListModule,
    IdentityComponent,
    VersionComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  appName: string         = environment.appName;
  environmentName: string = environment.environmentName;
  version: string         = environment.version;
  public users: User[]    = environment.users;

  public loginForm!: FormGroup;
  public error: string | null = null;

  constructor(
    public errorStateMatcher: FieldErrorStateMatcher,
    protected route: ActivatedRoute,
    protected router: Router,
    protected formBuilder: FormBuilder,
    protected snackBar: MatSnackBar,
    protected authService: AuthService,
  ) {

  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      email:        ["", [Validators.required, Validators.email]],
      password:     ["", [Validators.required]]
    });
  }

  loginAs(user: User) {
    if(!user.password) return;

    this.loginForm.setValue(
      {
        email: user.email,
        password: user.password
      }
    )

    this.login(this.loginForm.value);
  }

  onSubmit() {
    if(this.loginForm.invalid) {
      this.loginForm.markAsTouched();
      return;
    }

    this.login(this.loginForm.value);
  }

  login(credentials: AuthCredentials) {
    this.error = "";
    this.authService.login(credentials, true).subscribe(
      {
        next: () => {},
        error: (error: any) => {
          this.error = error.error.message ? error.error.message : error.error.error ? error.error.error : null;
        },
        complete: () => {}
      }
    );
  }

  getError(controlName: string): string | null {
    const control: AbstractControl | null = this.loginForm.get(controlName);

    return (control && control.errors)
      ? this.getErrorsString(controlName, control.errors)
      : null;
  }

  pending(controlName: string): boolean {
    const control: AbstractControl | null = this.loginForm.get(controlName);

    return (control && control.pending)
      ? true
      : false;
  }

  hasErrors(form: FormGroup, controlName: string): boolean {
    const control: AbstractControl | null = form.get(controlName);

    return (control && control.invalid)
      ? true
      : false;
  }

  getErrorsString(controlName: string, errors: ValidationErrors): string {
    const errorKeys: string[] = [];

    for (const [key, value] of Object.entries(errors)) {
      errorKeys.push(validationMessages[key] || key);
    }

    return errorKeys.join(", ");
  }

  openSnackBar(message: string, action: string | undefined = undefined) {
    this.snackBar.open(message, action, { duration: 3000, verticalPosition: "top" });
  }
}
