import { NgModule, Optional, SkipSelf } from "@angular/core";
import { FrioLibComponent } from "./components/frio-lib.component";

@NgModule({
  declarations: [
    FrioLibComponent
  ],
  imports: [

  ],
  exports: [
    FrioLibComponent
  ]
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
      if (parentModule) {
          throw new Error(
              "AuthModule is already loaded."
          );
      }
  }

  // static forRoot(options: AuthConfig): ModuleWithProviders<AuthModule> {
  //     console.log("xxx", options);

  //     return {
  //         ngModule: AuthModule,
  //         providers: [
  //             {
  //                 provide: AUTH_CONFIG,
  //                 useValue: options,
  //             },
  //             AuthService
  //         ],
  //     };
  // }
}
